import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import AboutBanner from '../sections/AboutBanner/AboutBanner';
// import PageTitle from '../components/PageTitle/PageTitle';
// import BlogCard from '../components/BlogCard/BlogCard';
// import BlogData from '../sections/Blogs/BlogData';
// import Faq from '../sections/Faq/Faq';
// import Priority from '../sections/Priority/Priority';
// import Safety from '../sections/Safety/Safety';
import Team from '../sections/Team/Team';
import Footer from '../sections/Footer/Footer';
import WhatsAppButton from "../components/WhatsappIcon/WhatsAppButton.js";
import ImageSection from '../sections/ImageSection/ImageSection';


const About = () => {
    return (

        <div
            style={{
                backgroundColor: "#f6f5f3",
            }}
        >

            <Navbar />
            <AboutBanner />
            <ImageSection />
            {/* <Priority /> */}
            {/* <Safety /> */}
            <Team />
            {/* <Faq /> */}
            <WhatsAppButton />
            <Footer />
        </div>
    );
};

export default About;