import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../sections/Footer/Footer';
import ServiceBanner from '../sections/ServiceBanner/ServiceBanner';
import Appointment from '../sections/Appointment/Appointment';
import ServiceCardsKids from "../sections/ServiceCardsKids/ServiceCardsKids";
import ServiceCards from "../sections/ServiceCards/ServiceCards";

import WhatsAppButton from "../components/WhatsappIcon/WhatsAppButton.js";


const Services = () => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f6f5f3",
            }}
        >
            <Navbar />
            <ServiceBanner />
            <ServiceCards />

            <ServiceCardsKids />
            {/* <Gallery /> */}
            <Appointment />
            <WhatsAppButton />
            <Footer />

        </div>
    );
};

export default Services;