import React from 'react';
import './ServiceCard.scss';
import { useTranslation } from "react-i18next";


const ServiceCard = ({ testimonial }) => {

    const { t } = useTranslation();

    const { img, name, description } = testimonial;

    return (
        <div className='col-lg-4 single-testimoni'>
            <div className="service-card">
                <div className="service-img">
                    <img src={img} />
                </div>
                <div className="service-text">
                    <h3>{t(`Services.${name}`)}</h3>
                    <p>{t(`Services.${description}`)}</p>
                </div>
            </div>
        </div>
    );
};

export default ServiceCard;
