import React, { useState, useEffect } from 'react';
import './ServiceCards.scss';
import ServiceCard from '../../components/ServiceCard/ServiceCard';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import imgOne from '../../assets/serviceCard/estetik_dis.JPG';

import imgThree from '../../assets/serviceCard/protetik.jpeg';
import imgFour from '../../assets/serviceCard/cerrahi.JPG';
import imgFive from '../../assets/serviceCard/eklem_splint.jpg';
import imgSix from '../../assets/serviceCard/dis_eti.jpeg';
import imgSeven from '../../assets/serviceCard/ortodonti.JPG';
import imgTwo from '../../assets/serviceCard/bio.jpeg';
import { Link } from "react-router-dom";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";


const treatments = [

    {
        "img": imgOne,
        "name": "serviceCardsTitle1",
        "description": "serviceCardsDescription1"
    },
    {
        "img": imgTwo,
        "name": "serviceCardsTitle2",
        "description": "serviceCardsDescription2"
    },
    {
        "img": imgThree,
        "name": "serviceCardsTitle3",
        "description": "serviceCardsDescription3"
    },
    {
        "img": imgFour,
        "name": "serviceCardsTitle4",
        "description": "serviceCardsDescription4"
    },
    {
        "img": imgFive,
        "name": "serviceCardsTitle5",
        "description": "serviceCardsDescription5"
    },
    {
        "img": imgSix,
        "name": "serviceCardsTitle6",
        "description": "serviceCardsDescription6"
    },
    {
        "img": imgSeven,
        "name": "serviceCardsTitle7",
        "description": "serviceCardsDescription7"
    }

];

const ServiceCards = () => {

    const [visibleCount, setVisibleCount] = useState(2); // Default 2 cards for mobile
    const [buttonPressCount, setButtonPressCount] = useState(0);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const { t } = useTranslation();

    const showMoreHandler = () => {
        if (buttonPressCount === 0) {
            setVisibleCount((prevCount) => prevCount + 6); // Show 8 cards on the first press
        } else if (buttonPressCount === 1) {
            setVisibleCount(treatments.length); // Show all cards on the second press
        }
        setButtonPressCount((prevCount) => prevCount + 1);
    };

    useEffect(() => {
        const updateScreenSize = () => {
            setScreenSize(window.innerWidth);
        };
        window.addEventListener('resize', updateScreenSize);

        // Adjust the visibleCount based on screen size
        if (screenSize < 768) {
            setVisibleCount(2); // Mobile (show 2 cards initially)
        } else if (screenSize >= 768 && screenSize <= 1024) {
            setVisibleCount(4); // Tablet (show 4 cards initially)
        } else {
            setVisibleCount(6); // Desktop (show 6 cards initially)
        }

        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, [screenSize]);
    return (
        <section className='service-cards pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className={"mt-5"}>
                    <SectionTitle title={t("Services.serviceCardsHeaderTitle")} />
                </div>
                <div className="service-grid" style={{ marginTop: "3rem" }}>
                    {treatments.slice(0, visibleCount).map((treatment, index) => (
                        <ServiceCard key={index} testimonial={treatment} />
                    ))}
                </div>
                {visibleCount < treatments.length && (
                    <div className="services-link text-center">
                        <Link onClick={showMoreHandler}>
                            {t("Services.otherTreatments")}
                            <BsFillArrowRightCircleFill />
                        </Link>
                    </div>
                )}
            </div>
        </section>
    );
};

export default ServiceCards;