import React, {useEffect} from 'react';
import './Navbar.scss';
import logo from './../../assets/dentista_grey_logo.png';
import kidsLogo from './../../assets/dentistaKids2.png';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from "react-i18next";

const Navbar = () => {
    const {pathname} = useLocation();
    const {i18n, t} = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    const handleScrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const toChange = i18n.language === "en" ? "tr" : "en"
    const changeLanguage = () => {
        i18n.changeLanguage(toChange);
    };

    const navbarItems = [
        {
            name: t("General.homePage"),
            path: '/',
        },
        {
            name: t("General.about"),
            path: '/hakkimizda',
        },
        {
            name: t("General.services"),
            path: '/hizmetler',
        }
    ];


    return (
        <div className='main-nav'>
            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">

                        <Link className="navbar-brand" to="/" onClick={handleScrollToTop}>
                            <img src={logo} alt="logo"/>
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">

                            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                                {
                                    navbarItems.map(navSingle =>
                                        <li className="nav-item">
                                            <Link className="nav-link" to={navSingle.path}
                                                  onClick={handleScrollToTop}>{navSingle.name}</Link>
                                        </li>
                                    )
                                }

                            </ul>

                            <div className={"mt-2"} style={{maxWidth: "12rem", marginRight: "6rem"}}>
                                <img src={kidsLogo} alt="kidsLogo"/>
                            </div>

                            <div className="theme-btn communication-btn">
                                <Link to="/iletisim" onClick={handleScrollToTop}>{t("General.contact")}</Link>
                            </div>

                            <div className={"language-text language-text-res"} style={{marginLeft: "auto"}}
                                 onClick={changeLanguage}>{i18n.language.toUpperCase()}</div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Navbar;