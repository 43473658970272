import React from 'react';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import './Services.scss';
import ServicesData from './ServiceData';
import Service from '../../components/Service/Service';
import { Link } from 'react-router-dom';
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Services = () => {
    const { t } = useTranslation();

    return (
        <section className='service-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-6">
                        <SectionTitle title={t("HomePage.qualityAndServices")} subTitle={t("General.services")} />
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <p className='service-title-text'></p>
                    </div>
                </div>

                <div className="row">
                    {
                        ServicesData.map((singleService, index) => (
                            <Service key={index} serviceList={singleService} />
                        ))
                    }
                </div>
            </div>

            <div className="services-link text-center">
                <Link to='/hizmetler'>
                    {t("HomePage.otherServices")}
                    <BsFillArrowRightCircleFill />
                </Link>
            </div>
        </section>
    );
};

export default Services;
