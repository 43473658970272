import React from 'react';
import './Banner.scss';
import bannerImg from '../../assets/banner/efee.png';
import { BiSolidPhoneCall } from "react-icons/bi";
import { useTranslation } from "react-i18next";

const Banner = () => {
    const { t } = useTranslation();

    return (
        <section className='section-common banner-section'>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="banner-text" data-aos="fade-up" data-aos-duration="2000">
                                    <h1 style={{ color: "#f3f2f0" }}>Dentista <br /> {t("HomePage.dentistaHeader")}</h1>
                                    <p style={{ color: "#f3f2f0", fontWeight: "500" }}>{t("HomePage.farFromStress")}</p>

                                    <div className="banner-bottom">
                                        <div className="banner-call">
                                            <div className='icon'
                                                style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                <BiSolidPhoneCall
                                                    style={{
                                                        color: "#FB5229",
                                                        marginLeft: "0.1rem",
                                                        marginTop: "0.5rem"
                                                    }}
                                                    size={23} />
                                            </div>
                                            <div className='call-text'>
                                                <p style={{ color: "#F6F5F3" }}>{t("HomePage.contactWp")}</p>
                                                <h6 style={{ color: "#F6F5F3" }}>Dentista: +90 553 052 66 48 <br /> Dentista Kids: +90 551 010 22 05</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="banner-img-area" data-aos="fade-up" data-aos-duration="2000"
                                    data-aos-delay="500" style={{ padding: "1rem" }}>
                                    <div className="banner-img">
                                        <img src={bannerImg} alt="banner model" style={{ opacity: 0.95 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;