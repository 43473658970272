import React from 'react';
import './Footer.scss';
import { FaInstagram } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { BiSolidPhoneCall } from "react-icons/bi";
import { FaClock } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();

    const footerMenu = [
        {
            'name': t("General.about"),
            'link': '/hakkimizda'
        },
        {
            'name': t("General.services"),
            'link': '/hizmetler'
        },
    ];

    const footerContacts = [
        {
            'title': t("Footer.contactNumbers"),
            'info': <div>Dentista: +90 553 052 66 48 <br /> Dentista Kids: +90 551 010 22 05</div>,
            'icon': <BiSolidPhoneCall style={{ color: "#FB5229", marginLeft: "0.1rem" }} size={23} />
        },
        {
            'title': t("Footer.workDate"),
            info: (
                <div className="working-hours">
                    <p>{t("Footer.monday")}: {t("Footer.closed")}</p>
                    <p>{t("Footer.tuesday")}: 10:00 - 19:00</p>
                    <p>{t("Footer.wednesday")}: 10:00 - 19:00</p>
                    <p>{t("Footer.thursday")}: 10:00 - 19:00</p>
                    <p>{t("Footer.friday")}: 10:00 - 19:00</p>
                    <p>{t("Footer.saturday")}: 10:00 - 19:00</p>
                    <p>{t("Footer.sunday")}: 10:00 - 19:00</p>
                </div>
            ),
            'icon': <FaClock style={{ color: "#FB5229", marginLeft: "0.1rem" }} size={23} />
        },
        {
            'title': t("Footer.address"),
            'info': t("HomePage.addressIst"),
            'icon': <FaLocationDot style={{ color: "#FB5229", marginLeft: "0.1rem" }} size={23} />
        }
    ];

    return (
        <footer className='pt-100 pb-70' style={{ backgroundColor: "#C5A47E" }}>
            <div className="container">
                <div className="row">

                    <div className="col-lg-6 col-md-5 mb-4">
                        <p style={{ color: "white" }}>{t("Footer.followUs")}</p>
                        <div className="social-logo">
                            <ul>
                                <li style={{ display: "block" }}>
                                    <a href="https://www.instagram.com/dentistadis" style={{ textDecoration: "none" }}>
                                        <FaInstagram size={25} />
                                        <text style={{ marginLeft: "0.8rem" }}>dentistadis</text>
                                    </a>
                                </li>
                                <li style={{ display: "block", marginTop: "0.6rem" }}>
                                    <a href="https://www.instagram.com/kids.dentista/" style={{ textDecoration: "none" }}>
                                        <FaInstagram size={25} />
                                        <text style={{ marginLeft: "0.8rem" }}>kids.dentista</text>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-2 mb-4">
                        <div className="footer-link">
                            <p style={{ color: "white" }}>{t("Footer.shortcuts")}</p>
                            <ul>
                                {
                                    footerMenu.map(singleMenu => (
                                        <li key={singleMenu.name}>
                                            <Link to={singleMenu.link}>{singleMenu.name}</Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-5">
                        <div className="footer-contact">
                            <p style={{ color: "white" }}>{t("Footer.contactAndInfo")}</p>

                            {
                                footerContacts.map((footerContact, index) => {
                                    // Adding class "working-hours" to only the second item
                                    const contactClass = footerContact.title === t("Footer.workDate") ? 'working-hours' : '';
                                    return (
                                        <div
                                            key={index}
                                            className={`contact-list ${contactClass}`}
                                        >
                                            <div className="contact-icon col-2">
                                                {footerContact.icon}
                                            </div>
                                            <div className="contact-text col-9">
                                                <p style={{ color: "white" }}>{footerContact.title}</p>
                                                <h5>{footerContact.info}</h5>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="copyright-area">
                    <div className='copy-text'>
                        <p style={{ color: "white" }}>&copy; Dentista. {t("Footer.allRights")}</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
