import imageOne from '../../assets/serviceCard/cerrahi.JPG';
import imageTwo from '../../assets/serviceCard/bio.jpeg';
import imageThree from '../../assets/serviceCard/protetik.jpeg';
import imageFour from '../../assets/serviceCard/dis_eti.jpeg';

const ServicesData = [
    {
        'image': imageOne,
        'title': 'serviceTitle1',
        'description': 'serviceDescription1',
    },
    {
        'image': imageTwo,
        'title': 'serviceTitle2',
        'description': 'serviceDescription2',
    },
    {
        'image': imageThree,
        'title': 'serviceTitle3',
        'description': 'serviceDescription3',
    },
    {
        'image': imageFour,
        'title': 'serviceTitle4',
        'description': 'serviceDescription4',
    }
]

export default ServicesData;
