import React from 'react';
import './ContactForm.scss';
import { AiFillPhone, AiFillMail } from 'react-icons/ai';
import { useTranslation } from "react-i18next";

const ContactForm = () => {
    const { t } = useTranslation();

    return (
        <div className="contact-info">
            <div className="info-item">
                <AiFillPhone className="info-icon" />
                <div>
                    <h5>{t("HomePage.phoneNumbers")}</h5>
                    <p><div>Dentista: +90 553 052 66 48 <br /> Dentista Kids: +90 551 010 22 05</div></p>
                </div>
            </div>
            <div className="info-item">
                <AiFillMail className="info-icon" />
                <div>
                    <h5>Email</h5>
                    <p>dentistagop@gmail.com - dentistacocuk@gmail.com</p>
                </div>
            </div>
            {/*<div className="info-item">
                <AiFillHome className="info-icon" />
                <div>
                    <h5>Address</h5>
                    <p>123 Main Street, Istanbul, Turkey</p>
                </div>
            </div>*/}
        </div>
    );
};
export default ContactForm;