import React from 'react';
import './TestimoniCard.scss';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

const TestimoniCard = ({ testimonail }) => {
    const { name, description, ratings } = testimonail;

    // Create an array of 5 stars, filled or outlined based on the rating
    const renderStars = (rating) => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(
                i < rating ? (
                    <AiFillStar key={i} className="filled" />
                ) : (
                    <AiOutlineStar key={i} className="outlined" />
                )
            );
        }
        return stars;
    };

    return (
        <div className='col-lg-4 single-testimoni'>
            <div className="testimonial-card">
                <div className="testimonial-img">
                    {/* Optionally, you can add an image here */}
                </div>
                <div className="testimonial-text">
                    <h3>{name}</h3>
                    <p>{description}</p>
                </div>
                <ul className='testimonial-rating'>
                    {renderStars(ratings)} {/* Render stars dynamically */}
                </ul>
            </div>
        </div>
    );
};

export default TestimoniCard;
