import React, {useMemo} from 'react';
import './Testimonial.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import TestimoniCard from '../../components/TestimoniCard/TestimoniCard';
import Slider from "react-slick";
import {useTranslation} from "react-i18next";
// import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const Testimonial = () => {
    const {t} = useTranslation();

    const testimonails = [
        {
            'name': 'Tanem Özge',
            'description': '7 yaşında ki kızımın dişleri 3.4 yaşlarında ki kötü tedavi deneyımlerımlerimizden dolayı korkunç hale gelmişti. Toparlamanın çok zorlu oldugunu beklerken Dentista ekibi muhteşem ilgi ve deneyımlerımleriyle gayet başarılı ve sorunsuz bir tedavi süreçi geçirdik.Sürecimiz uzun olmasına rağmen kızım kesinlikle dişçi koltugu endişesi yaşamadı. Gizem hanım, Melih bey, Narin hanım ve Efe Bey’ e ilgilerinden dolayı çok teşekkür ederim.🥰 Klinik olarak tertemiz ve modern ayrıca bekleme alanı çocuklara özel tasarlanmış, şahane🌸 Çalışanlar işinizi kolaylastırmak için orada☺️ Kızımın öz güveni yerinde inci dişler , mutlu gülüşler😍 Umarım rutin kontroller de yeniden bir araya geliriz. Her şey için teşekkürler 🙏 Sevgilerle..',
            'ratings': 5
        },
        {
            'name': 'Sabahattin Kocatürk',
            'description': 'Temiz ve düzenli bir klinik, gördüğüm kadarıyla güncel teknolojiyi yakından takip eden ve kaliteli ürünler kullanan güzel bir sağlık kuruluşu.',
            'ratings': 5
        },
        {
            'name': 'Özgen Kırmızıbekmez',
            'description': 'Narin hanım en başından beri çok ilgili davrandı ve kanal tedavisi gerektiği söylenen dişimi daha uzun süre sağlıkla kullanmamı sağlamak için dolgu yaparak kurtardı. Üstelik işlem sonrası hiçbir ağrı olmadı. Hayatımda yaşadığım en konforlu diş hekimi tecrübesiydi. Ekipleri de oldukça güleryüzlü, herkese tavsiye ederim. Kesinlikle çok profesyoneller ve baştan savma bir tedavi yapılmıyor. Her aşamasını fotoğraflar ile gösteriyorlar ve ağzıma ne yapıldığını görüyorum 🙂',
            'ratings': 5
        },
        {
            'name': 'Fatih Meriç',
            'description': 'Özellikle doktor fobiniz varsa, mükemmel enerjileriyle diş tedavisini bile keyifli hale getirebiliyorlar. Teşekkürler Doktor Narin hanım.',
            'ratings': 5
        },
        {
            'name': 'Aleyna Karabak',
            'description': 'Arkadaşımın tavsiyesi üzerine gittiğim bu kliniği ben de herkese gönül rahalığıyla tavsiye edebiliyorum. 2 tane implant ve diş taşı temizliği işlemlerim yapıldı. Güzel yüzlü ve deneyimli ekibiyle güven veren bir klinik. Efe Bey\'e ve ekibine çok teşekkürler...',
            'ratings': 5
        },
        {
            'name': 'Görkem Yahya Turan',
            'description': 'Alanında profesyonel bir ekip ve harika dizayn edilmiş bir klinik.\n' +
                'Emre, Efe, Narin ve Ahmet hocalara ilgi ve alakaları ayrı ayrı teşekkürler.\n' +
                'Gönül rahatlığıyla tercih edebilirsiniz.',
            'ratings': 5
        },
        {
            'name': 'Eda Çil',
            'description': 'Güler yüzlü personeli ve deneyimli hekimleriyle her daim tercih ettiğim mükemmel bir klinik. Özellikle çarşamba günleri gelen çocuk diş hekimi çok bilgili, çok tatlı. Tereddütsüz gidebilirsiniz.',
            'ratings': 5
        },
        {
            'name': 'Hüseyin Koçak',
            'description': 'Kendinizi evinizde gibi hissettiğiniz özel bir yer.Efe bey ve Narin hanım işlerini çok iyi ve detaylı yapıyorlar.İslem yapılmadan önce ve işlem anında detaylı bilgi vermeleri ise hastaya olan saygılarının göstergesi.Ben ve ailem ile tavsiye ettiğim dostlarımın her zaman memnuniyetini alan Dentista\'ya çok teşekkürler.',
            'ratings': 5
        },
        {
            'name': 'Betül Mülayim',
            'description': 'Genel muayene için efe beye gittim. Gerçekten güzel muayene etti ve oradaki güler yüzlü ile karşılaşmamdan dolayı teşekkür ederim. Tereddütsüz gidebilirsiniz.',
            'ratings': 5
        }
    ];

    const shuffledTestimonials = useMemo(() => {
        return testimonails.sort(() => Math.random() - 0.5);
    }, []);

    // const NextArrow = ({onClick}) => {
    //     return <div className="arrow next" onClick={onClick}><FaArrowRight/></div>;
    // };

    // const PrevArrow = ({onClick}) => {
    //     return <div className="arrow prev" onClick={onClick}><FaArrowLeft/></div>;
    // };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        /*nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,*/
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <section className='testimonail-section section-bg section-common pt-100 pb-70' data-aos="fade-up"
                 data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <SectionTitle
                            title={t("HomePage.commentsFrom")}
                        />
                    </div>
                    <div className="col-lg-6">
                        <p className="pt-5" style={{color: "#181945"}}>{t("HomePage.healthAndHappiness")}</p>
                    </div>
                </div>

                <Slider {...settings} className="testimoni-slider">
                    {
                        shuffledTestimonials.map((testimonail, index) => (
                            <TestimoniCard key={index} testimonail={testimonail}/>
                        ))
                    }
                </Slider>
            </div>
        </section>
    );
};

export default Testimonial;