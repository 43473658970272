import iconOne from '../../assets/features/11.png';
import iconTwo from '../../assets/features/22.png';
import iconThree from '../../assets/features/33.png';
import iconFour from '../../assets/features/44.png';

const featuresData = [
    {
        'icon': iconOne,
        'title': 'featuresTitle1',
        'description': 'featuresDescription1'
    },
    {
        'icon': iconThree,
        'title': 'featuresTitle2',
        'description': 'featuresDescription2'
    },
    {
        'icon': iconFour,
        'title': 'featuresTitle3',
        'description': 'featuresDescription3'
    },
    {
        'icon': iconTwo,
        'title': 'featuresTitle4',
        'description': 'featuresDescription4'
    },
]

export default featuresData;