import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Appointment from "../sections/Appointment/Appointment";
import Banner from "../sections/Banner/Banner";
// import Blogs from "../sections/Blogs/Blogs";
import Emergency from "../sections/Emergency/Emergency";
//import Expert from "../sections/Expert/Expert";
import Features from "../sections/Features/Features";
import Footer from "../sections/Footer/Footer";
import Services from "../sections/Services/Services";
import Testimonial from "../sections/Testimonial/Testimonial";
// import Team from "../sections/Team/Team";
import WhatsAppButton from "../components/WhatsappIcon/WhatsAppButton.js";

const Home = () => {

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f6f5f3",
            }}
        >
            <Navbar />
            <Banner />
            <Services />
            <Features />
            {/* <Team /> */}
            {/* <Expert/>*/}
            <Emergency />
            <Testimonial />
            {/* <Blogs /> */}
            <Appointment />
            <Footer />
            <WhatsAppButton />
        </div>
    );
};

export default Home;
