import React, { useState, useEffect } from 'react';
import './ServiceCardsKids.scss';  // Updated to reflect new file name
import ServiceCard from '../../components/ServiceCard/ServiceCard';
import SectionTitle from '../../components/SectionTitle/SectionTitle';

import imgOne from '../../assets/features/happy_tooth.png';
import { Link } from "react-router-dom";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const treatments = [
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle1",
        "description": "serviceCardsKidsDescription1"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle2",
        "description": "serviceCardsKidsDescription2"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle3",
        "description": "serviceCardsKidsDescription3"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle4",
        "description": "serviceCardsKidsDescription4"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle5",
        "description": "serviceCardsKidsDescription5"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle6",
        "description": "serviceCardsKidsDescription6"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle7",
        "description": "serviceCardsKidsDescription7"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle8",
        "description": "serviceCardsKidsDescription8"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle9",
        "description": "serviceCardsKidsDescription9"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle10",
        "description": "serviceCardsKidsDescription10"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle11",
        "description": "serviceCardsKidsDescription11"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle12",
        "description": "serviceCardsKidsDescription12"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle13",
        "description": "serviceCardsKidsDescription13"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle14",
        "description": "serviceCardsKidsDescription14"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle15",
        "description": "serviceCardsKidsDescription15"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle16",
        "description": "serviceCardsKidsDescription16"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle17",
        "description": "serviceCardsKidsDescription17"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle18",
        "description": "serviceCardsKidsDescription18"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle19",
        "description": "serviceCardsKidsDescription19"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle20",
        "description": "serviceCardsKidsDescription20"
    },
    {
        "img": imgOne,
        "name": "serviceCardsKidsTitle21",
        "description": "serviceCardsKidsDescription21"
    }
];

const ServiceCardsKids = () => {
    const [visibleCount, setVisibleCount] = useState(2); // Default 2 cards for mobile
    const [buttonPressCount, setButtonPressCount] = useState(0);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    const { t } = useTranslation();

    const showMoreHandler = () => {
        if (buttonPressCount === 0) {
            setVisibleCount((prevCount) => prevCount + 6); // Show 8 cards on the first press
        } else if (buttonPressCount === 1) {
            setVisibleCount(treatments.length); // Show all cards on the second press
        }
        setButtonPressCount((prevCount) => prevCount + 1);
    };

    useEffect(() => {
        const updateScreenSize = () => {
            setScreenSize(window.innerWidth);
        };
        window.addEventListener('resize', updateScreenSize);

        // Adjust the visibleCount based on screen size
        if (screenSize < 768) {
            setVisibleCount(2); // Mobile (show 2 cards initially)
        } else if (screenSize >= 768 && screenSize <= 1024) {
            setVisibleCount(4); // Tablet (show 4 cards initially)
        } else {
            setVisibleCount(6); // Desktop (show 6 cards initially)
        }

        return () => {
            window.removeEventListener('resize', updateScreenSize);
        };
    }, [screenSize]);

    return (
        <section className='service-cards-kids pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container" style={{ marginTop: "-10rem" }}>
                <SectionTitle title="Çocuklar İçin Tedavilerimiz" />
                <div className="service-grid" style={{ marginTop: "3rem" }}>
                    {treatments.slice(0, visibleCount).map((treatment, index) => (
                        <ServiceCard key={index} testimonial={treatment} />
                    ))}
                </div>
                {visibleCount < treatments.length && (
                    <div className="services-link text-center">
                        <Link onClick={showMoreHandler}>
                            {t("Services.otherTreatments")}
                            <BsFillArrowRightCircleFill />
                        </Link>
                    </div>
                )}
            </div>
        </section>
    );
};

export default ServiceCardsKids;