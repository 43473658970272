import React from "react";
import "./WhatsAppButton.scss";

const WhatsAppButton = () => {
    return (
        <a href="https://wa.me/905530526648" target="_blank" rel="noopener noreferrer" className="whatsapp-button">
            <img
                src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
                alt="WhatsApp"
                className="whatsapp-icon"
            />
        </a>
    );
};

export default WhatsAppButton;
