import React, { useState } from 'react';
import './Team.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import imgOne from '../../assets/about/team/efe_kaya.jpg';
import imgTwo from '../../assets/about/team/mehmet_ali.jpeg';
import imgThree from '../../assets/about/team/narin_kaya.jpg';
import imgFour from '../../assets/about/team/melih_acele.jpg';
import imgFive from '../../assets/about/team/bircan_gursoy.JPG';
import imgSix from '../../assets/about/team/tugce_gurbuzturk.JPG';

import { useTranslation } from "react-i18next";

const Team = () => {
    const { t } = useTranslation();

    const teams = [
        {
            img: imgOne,
            name: t('About.teamMember1Name'),
            bio: t('About.teamMember1Bio'),
        },
        {
            img: imgTwo,
            name: t('About.teamMember2Name'),
            bio: t('About.teamMember2Bio'),
        },
        {
            img: imgThree,
            name: t('About.teamMember3Name'),
            bio: t('About.teamMember3Bio'),
        },
        {
            img: imgFour,
            name: t('About.teamMember4Name'),
            bio: t('About.teamMember4Bio'),
        },
        {
            img: imgFive,
            name: t('About.teamMember5Name'),
            bio: t('About.teamMember5Bio'),
        },
        {
            img: imgSix,
            name: t('About.teamMember7Name'),
            bio: t('About.teamMember7Bio'),
        }
    ];

    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section className="team-section pt-100" data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <SectionTitle subTitle={t("About.meetTeam")} title={t("About.meetTeamHeader")} />
                    </div>
                    <div className="col-lg-5">
                        <p className="pt-5">{t("About.solutions")}</p>
                    </div>
                </div>

                <div className="row upper-row justify-content-center">
                    {teams.slice(0, 3).map((member, index) => (
                        <div className="col-lg-3 col-sm-6" key={index}>
                            <div className="team-card">
                                <div className={`team-img ${activeIndex === index ? 'active' : ''}`} onClick={() => handleToggle(index)}>
                                    <img src={member.img} alt={member.name} />
                                    <div className="team-overlay">
                                        <p>{member.bio}</p>
                                    </div>
                                </div>
                                <h3>{member.name}</h3>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="row lower-row justify-content-center">
                    {teams.slice(3).map((member, index) => (
                        <div className="col-lg-3 col-sm-6" key={index + 3}>
                            <div className="team-card">
                                <div className={`team-img ${activeIndex === index + 3 ? 'active' : ''}`} onClick={() => handleToggle(index + 3)}>
                                    <img src={member.img} alt={member.name} />
                                    <div className="team-overlay">
                                        <p>{member.bio}</p>
                                    </div>
                                </div>
                                <h3>{member.name}</h3>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Team;
