import './App.scss';
import './i18n';
import Home from './pages/Home';
import {Routes, Route} from 'react-router-dom';
import About from './pages/About';
import Services from './pages/Services';
import Contactus from './pages/Contact/Contactus';
import DentistaKidsPopUp from './components/DentistaKidsPopUp/dentistaKidsPopUp';

function App() {
    return (
        <>
            <DentistaKidsPopUp/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/hakkimizda" element={<About/>}/>
                <Route path="/hizmetler" element={<Services/>}/>
                <Route path="/iletisim" element={<Contactus/>}/>
            </Routes>
        </>
    );
}

export default App;
