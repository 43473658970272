import React from 'react';
import { Link } from 'react-router-dom';
import './Service.scss';
import { useTranslation } from "react-i18next";

const Service = ({ serviceList }) => {
    const { title, description, image } = serviceList;
    const { t } = useTranslation();

    return (
        <div className='col-lg-3 col-md-4 col-sm-6'>
            <div className="service-box">
                <div className="service-image" style={{ backgroundImage: `url(${image})` }}></div>
                <div className="service-text">
                    <h3>{t(`HomePage.${title}`)}</h3>
                    <p>{t(`HomePage.${description}`)}</p>
                </div>
            </div>
        </div>
    );
};

export default Service;