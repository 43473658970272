import React from 'react';
import './ImageSection.scss';

// Importing images
import imgOne from '../../assets/about/image_section/clinic1_169.png';
import imgTwo from '../../assets/about/image_section/clinic2_169.png';
import imgThree from '../../assets/about/image_section/clinic3_169.png';

const ImageSection = () => {
  const images = [imgOne, imgTwo, imgThree];

  return (
    <section className="image-section" data-aos="fade-up" data-aos-duration="2000" style={{ width: "100%" }}>
      {images.map((src, index) => (
        <img key={index} src={src} alt={`team-member-${index + 1}`} className="image-item" />
      ))}
    </section>
  );
};

export default ImageSection;
