import React from 'react';
import './Features.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import IconList from '../../components/IconList/IconList';
import featuresData from './FeaturesData';
import {useTranslation} from "react-i18next";

const Features = () => {
    const {t} = useTranslation();

    return (
        <section className='section-bg section-common features-section pt-100 pb-70' data-aos="fade-up"
                 data-aos-duration="2000">
            <div className="container">
                <SectionTitle subTitle={t("HomePage.provide")} title={t("HomePage.experience")}
                              description={t("HomePage.longYears")}/>

                <div className="row align-items-center">
                    {
                        featuresData.map(singleFeature =>
                            <IconList
                                icon={singleFeature.icon}
                                title={singleFeature.title}
                                description={singleFeature.description}
                            />
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default Features;