import React, {useState} from "react";
import "./dentistaKidsPopUp.scss";
import popup from './../../assets/dentistaKidsPopup.png';

const DentistaKidsPopUp = () => {
    const [isVisible, setIsVisible] = useState(true);

    const togglePopup = () => {
        setIsVisible((prev) => !prev);
    };

    return (
        <>
            <div className={`popup-card ${!isVisible ? "hidden" : ""}`}>
        <span className="close-icon" onClick={togglePopup}>
          &times;
        </span>
                <a
                    href="https://wa.me/905510102205"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={popup} alt="popup"/>
                </a>
            </div>
            {!isVisible && (
                <button className="reopen-button" onClick={togglePopup}>
                    &lt;
                </button>
            )
            }
        </>
    )
        ;
};

export default DentistaKidsPopUp;
