import React from 'react';
import './Emergency.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import EmergencyImg from '../../assets/slowDentistry.png';
import { useTranslation } from "react-i18next";

const Emergency = () => {
    const { t } = useTranslation();

    return (
        <section className='emergency-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="emergency-img">
                            <img src={EmergencyImg} alt="Emergency" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="emergency-text">
                            <SectionTitle subTitle={t("HomePage.trueService")}
                                title={t("HomePage.happyFace")}
                                description={t("HomePage.listenUnderstand")} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Emergency;