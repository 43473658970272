import React from 'react';
import './AboutBanner.scss';
import bannerOne from '../../assets/about/banner/about_banner_1.jpeg';
import bannerTwo from '../../assets/about/banner/about_banner_22.jpg';
import petFriendly from '../../assets/about/banner/pet_friendly.png';
import { useTranslation } from 'react-i18next';

const AboutBanner = () => {
    const { t } = useTranslation();

    return (
        <section className='about-section' data-aos="fade-up" data-aos-duration="2000">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5">
                                <div className="about-banner-text">
                                    <h2>{t("About.aboutUs")}</h2>
                                    <p>{t("About.healthAndAesthetic")}</p>
                                    <div className="pet-friendly-img">
                                        <img src={petFriendly} alt="Pet Friendly" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="about-banner-img">
                                    <img src={bannerOne} alt="about banner" />
                                    <img src={bannerTwo} alt="about banner two" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutBanner;
