import React from 'react';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import Navbar from '../../components/Navbar/Navbar';
import './Contactus.scss';
import ContactForm from '../../components/ContactForm/ContactForm';
import Footer from '../../sections/Footer/Footer';
import {AiFillHome} from "react-icons/ai";
import {useTranslation} from "react-i18next";

const Contactus = () => {
    const {t} = useTranslation();

    const mapLink = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3008.4933356227198!2d28.918334776523704!3d41.058207771343454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab138095fece3%3A0x9bb4406a69b4063e!2zRGVudGlzdGEgQcSfxLF6IHZlIERpxZ8gU2HEn2zEscSfxLEgUG9saWtsaW5pxJ9p!5e0!3m2!1sen!2str!4v1734615593664!5m2!1sen!2str'

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f6f5f3",
            }}>

            <section className='section-bg section-common contact-section'>
                <Navbar/>
                <SectionTitle
                    title={t("General.contact")}
                    description={t("Contact.hereForYourHealth")}
                />
            </section>
            <section className='contact-form-area' data-aos="fade-up" data-aos-duration="2000" style={{width: "100%"}}>

                <div className="google-map">
                    <iframe title='map' src={mapLink}></iframe>

                    <div className="location-name">
                        <AiFillHome style={{backgroundColor: "#FB5229"}}/>
                        <p>{t("HomePage.addressIst")}</p>
                    </div>
                </div>

                <div style={{marginTop: "-2rem"}}>
                    <ContactForm/>
                </div>

            </section>
            <Footer/>
        </div>
    );
};

export default Contactus;